import Head from "next/head";
import styled from "styled-components";

import WebsiteLayout from "@/components/layouts/websiteLayout";
import useWindowSize from "@/hooks/useWindowSize";
import ChatProvider from "@/provider/ChatProvider";
import { fontUtil } from "@/styles/website/font";
import Media from "@/styles/website/Media";
import { backgroundImgCover, ResponsiveBlock } from "@/styles/website/mixin";
import settings from "../configs";

const Home = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize === "mobile";
  return (
    <>
      <Head>
        <title>쏘카일레클 | 공유 전기자전거 서비스 SOCAR elecle</title>
      </Head>
      <WebsiteLayout>
        <ChatProvider>
          <MainFullImage>
            <Title>
              쏘카일레클,&nbsp;
              {isMobile && <br />}
              모든 이동의 시작과 끝
            </Title>
            <SubTitle>
              모든 이동의 시작과 끝은 쏘카일레클이 책임집니다.
            </SubTitle>
            <SubTitle>이제 쏘카일레클로 새로운 이동을 경험해보세요.</SubTitle>
          </MainFullImage>
          <Container>
            <MidSection>
              <div
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="100"
                data-aos-duration="500"
                data-aos-easing="ease-in"
                data-aos-mirror="false"
                data-aos-once="false"
              >
                <p className="section_title">
                  이동을 개선하여 {isMobile && <br />}
                  일상의 여유를 늘립니다.
                </p>
                <p className="section_sub">
                  자동차로 꽉 막힌 도로, 사람들로 가득찬 {isMobile && <br />}
                  지하철, 한참을 돌아가는 버스.
                </p>
                <p className="section_sub">
                  우리의 일상에서 떼어놓을 수 없는 '이동
                  {isMobile && <br />}
                  '은 지금 어떤 모습인가요?
                </p>
              </div>
              <MidSectionImageWrapper>
                <MidSectionImage src="/static/website/home/sub1.jpg" />
              </MidSectionImageWrapper>
            </MidSection>
          </Container>
          <BottomSection>
            <BottomSectionInnerBox
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in"
              data-aos-mirror="false"
              data-aos-once="false"
            >
              <BottomTitle>
                쏘카일레클은 이동의 새로운
                {isMobile && <br />} 표준을 제시합니다.
              </BottomTitle>
              <BottomSub>
                더욱 빠르고 편리한 이동으로 일상 속 여유를 늘린다면
                {!isMobile && <br />} 정말 필요한 곳에 우리의 소중한 시간과
                에너지를 쓸 수 있지 않을까요?
              </BottomSub>
            </BottomSectionInnerBox>
          </BottomSection>
        </ChatProvider>
      </WebsiteLayout>
    </>
  );
};

export default Home;

type BottomSectionInnerBoxProps = {
  children: React.ReactNode;
};

type MidSectionImageProps = {
  src: string;
};

const MainFullImage = styled.div`
  background-image: linear-gradient(
      rgba(51, 51, 51, 0.3),
      rgba(51, 51, 51, 0.3)
    ),
    url(${settings.CDN_DOMAIN}/website/home-main.png);
  ${backgroundImgCover};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
  position: relative;

  ${Media.mobile`
    justify-content: flex-start;
    padding-top: 120px;
  `}
`;

const Title = styled.h1`
  ${fontUtil("hero")}
  margin-bottom: 20px;
  z-index: 10;

  ${Media.mobile`
    text-align: center;
  `}
`;

const SubTitle = styled.h2`
  ${fontUtil("p1")};
  z-index: 10;
`;

const Container = styled.div`
  ${ResponsiveBlock}
  margin: 0 auto;

  ${Media.tablet`
    padding: 0 24px;
  `}

  ${Media.mobile`
    padding: 0 16px;
  `}
`;

const MidSection = styled.section`
  margin: 130px 0;

  .section_title {
    margin-bottom: 30px;
    ${fontUtil("h1")}
    color: ${(props) => props.theme.colors.gray001};
  }

  .section_sub {
    ${fontUtil("p1")}
    color: ${(props) => props.theme.colors.gray002};
  }

  ${Media.mobile`
    margin: 100px 0;
  `}
`;

const MidSectionImageWrapper = styled.div`
  margin-top: 95px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 10px;

  ${Media.tablet`
    padding-right: 0;
  `}

  ${Media.mobile`
    margin-top: 50px;
    justify-content: flex-start;
  `}
`;

const MidSectionImage = styled.img<MidSectionImageProps>`
  width: 712px;

  ${Media.desktop`
    width: 590px;
  `}

  ${Media.mobile`
    width: 100%;
  `}
`;

const BottomSection = styled.section`
  background-image: linear-gradient(
      rgba(51, 51, 51, 0.3),
      rgba(51, 51, 51, 0.3)
    ),
    url(${settings.CDN_DOMAIN}/website/home-sub2.png);
  ${backgroundImgCover}
  height: 815px;
  position: relative;
  display: flex;
  align-items: flex-end;

  ${Media.desktop`
    height: 790px;
  `}

  ${Media.tablet`
    height: 525px;
  `}
`;

const BottomSectionInnerBox = styled.div<BottomSectionInnerBoxProps>`
  ${ResponsiveBlock}
  margin: 0 auto;
  z-index: 10;
  width: 100%;
  padding-bottom: 75px;

  ${Media.desktop`
  padding-bottom: 75px;
  `}

  ${Media.tablet`
    padding-bottom: 110px;
  `}

  ${Media.mobile`
    padding-bottom: 36px;
  `}
`;

const BottomTitle = styled(Title)`
  color: white;
  text-align: left;
  ${fontUtil("h1")}
  margin-bottom: 30px;
`;

const BottomSub = styled(SubTitle)`
  color: white;
  ${fontUtil("p1")}

  ${Media.mobile`
    max-width: 270px;
  
  `}
`;
